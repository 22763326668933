<template>
  <div class="main-window">
    <div class="mn-page-main__side">
    </div>

    <main>
      <div class="main-panel">
        <div class="content-all">
          <div class="content-header">
            <p class="title"></p>
            <p class="title2"><i>Ориентировщика отличает наблюдательность, выносливость, самостоятельность, способность ориентироваться в сложной обстановке, умение выбирать рациональное решение.</i></p>

          </div>

          <div class="content-body" >
            <h1 class="chart-header"> 
<!--
              <img src="@/img/HI-BI.png" style="vertical-align:middle" alt="HI-BI logo"
                title="HI-BI system" loading="lazy">
-->
              &nbsp;ORun - ориентирование для новичков и продвинутых, от мала до велика
            </h1>
            <div class="content-paragraph">
              orun.aiviron.com - сайт открытого некоммерческого виртуального клуба ориентировщиков. Он позволяет пользователям размещать информацию и новости о мероприятиях, публиковать статьи, дистанции, треки.
            </div>
            <div class="content-paragraph">
              Цель сайта - популяризация ориентирования, приобщение к здоровому образу жизни.
            </div>

            <div class="content-paragraph">
              <div class="content-quote">
                <p>
                  Тактическое мастерство ориентировщика заключается в умении быстро и правильно ориентироваться во всех открывающихся ситуациях ландшафта, его картографического отображения, в условиях соревновательной борьбы мгновенно анализировать ситуацию и выбирать наиболее целесообразные действие.
                </p>
                <p align="right">
                  П.П. Миронов. "От новичка до мастера в ориентировании".
                  <br><br>
                </p>
                <p>
                  В ориентировании имеет смысл местность любить, карту благодарить, на соперников стараться не обращать никакого внимания, а бороться лишь с собой за максимальнейшую реализацию своих возможностей.
                </p>
                <p align="right">
                  В.В. Костылев. "Философия спортивного ориентирования".
                </p>

              </div>
            </div>
          </div> 
        </div>
      </div>
    </main>
    
    <div class="mn-page-main__side">
    </div>
  </div>
</template>

<script>
  
export default {
  data() {
    return {
    }
  },
  async created () {
    try {
      
      await this.$store.dispatch('set_interfaces')
      
    } catch (error) {
      console.log('set_interfaces error:', error)
    } 
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/main.scss';
</style>
  