<template>
  <div id="login" class="box has-text-centered">
    <div class="flex-container">

      <div :dir="'ltr'" class="treeselect" v-if="renderComponent">
        <Treeselect 
          v-model="value" 
          :options="options" 
          :clearable="true"
          :searchable="true"
          :disabled="false"
          :openOnClick="true"
          :openOnFocus="false"
          :closeOnSelect="treeOptions.closeonselect"
          :alwaysOpen="false"
          :appendToBody="false"
          :showCount="treeOptions.showCount"
          :disable-branch-nodes="treeOptions.disablebranchnodes"

          :multiple="treeOptions.multiple"
          :flat="treeOptions.flat" 

          :disableFuzzyMatching="!treeOptions.fuzzyMatching"
          :flattenSearchResults="treeOptions.flattenSearchResults"
          :searchNested="treeOptions.searchNested"

          :valueConsistsOf="treeOptions.valueconsistsof"
          placeholder="Select or(and) type item(s)..."
        />
      </div>

      <button class="svg-button" v-if="(!this.treeOptions.multiple) && editMode && isSelected" @click="editItem">
        <svg viewBox="0.002 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <path style="stroke: rgb(216, 216, 216); fill: rgb(216, 216, 216);" d="M 20.79 5.162 L 15.84 0.212 C 15.704 0.076 15.52 0 15.328 0 C 15.137 0 14.953 0.076 14.817 0.212 L 0.369 14.66 C 0.238 14.791 0.163 14.966 0.157 15.15 L 0.002 20.255 C -0.004 20.454 0.072 20.647 0.213 20.788 C 0.349 20.924 0.533 21 0.724 21 C 0.732 21 0.739 21 0.747 21 L 5.852 20.844 C 6.036 20.839 6.211 20.763 6.341 20.633 L 20.79 6.184 C 21.072 5.902 21.072 5.444 20.79 5.162 Z M 5.521 19.408 L 1.47 19.531 L 1.594 15.48 L 10.378 6.696 L 14.306 10.624 L 5.521 19.408 Z M 15.328 9.601 L 11.4 5.674 L 15.328 1.745 L 19.256 5.673 L 15.328 9.601 Z"/>
        </svg>      
      </button>

      <button class="svg-button" v-if="(!this.treeOptions.multiple && editMode && isSelected)" @click="newItem" >
        <svg viewBox="0 0 21.496 21.499" xmlns="http://www.w3.org/2000/svg">
          <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="matrix(1, 0, 0, 1, -3.003876, -3.003786)">
            <g id="ic_fluent_add_28_regular" fill="#212121" fill-rule="nonzero">
              <path style="stroke: rgb(216, 216, 216); fill: rgb(216, 216, 216);" d="M14.5,13 L14.5,3.75378577 C14.5,3.33978577 14.164,3.00378577 13.75,3.00378577 C13.336,3.00378577 13,3.33978577 13,3.75378577 L13,13 L3.75387573,13 C3.33987573,13 3.00387573,13.336 3.00387573,13.75 C3.00387573,14.164 3.33987573,14.5 3.75387573,14.5 L13,14.5 L13,23.7523651 C13,24.1663651 13.336,24.5023651 13.75,24.5023651 C14.164,24.5023651 14.5,24.1663651 14.5,23.7523651 L14.5,14.5 L23.7498262,14.5030754 C24.1638262,14.5030754 24.4998262,14.1670754 24.4998262,13.7530754 C24.4998262,13.3390754 24.1638262,13.0030754 23.7498262,13.0030754 L14.5,13 Z" id="🎨-Color"/>
            </g>
          </g>
        </svg>
      </button>

      <button class="svg-button" @click="toggleViewOption" v-if="isViewTreeOption !== true">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(0.375, 0, 0, 0.375, 12, 12)" id="593893">
            <g style="" vector-effect="non-scaling-stroke">
              <g transform="matrix(1 0 0 1 0 0)" id="Layer_3">
                <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: none; fill-rule: nonzero; opacity: 1;" x="-32" y="-32" rx="0" ry="0" width="64" height="64"/>
              </g>
              <g transform="matrix(1 0 0 1 0.03 -0.0056)" id="Design_Convert">
                <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" translate(-32.03, -31.9944)" d="M 38.81 57 a 1 1 0 0 1 -0.74 -0.33 l -1.28 -1.41 a 6.45 6.45 0 0 0 -9.58 0 l -1.28 1.41 a 1 1 0 0 1 -1.12 0.25 l -5.35 -2.21 a 1 1 0 0 1 -0.61 -1 l 0.09 -1.9 a 6.45 6.45 0 0 0 -6.77 -6.77 l -1.91 0.09 a 1 1 0 0 1 -1 -0.61 L 7.08 39.19 a 1 1 0 0 1 0.25 -1.12 l 1.41 -1.28 a 6.45 6.45 0 0 0 0 -9.58 L 7.33 25.93 a 1 1 0 0 1 -0.25 -1.12 l 2.21 -5.35 a 1 1 0 0 1 1 -0.61 l 1.91 0.09 a 6.45 6.45 0 0 0 6.77 -6.77 l -0.09 -1.9 a 1 1 0 0 1 0.61 -1 l 5.35 -2.21 a 1 1 0 0 1 1.12 0.25 l 1.28 1.41 a 6.45 6.45 0 0 0 9.58 0 l 1.28 -1.41 a 1 1 0 0 1 1.12 -0.25 l 5.35 2.21 a 1 1 0 0 1 0.61 1 l -0.09 1.9 a 6.45 6.45 0 0 0 6.77 6.77 l 1.91 -0.09 a 1 1 0 0 1 1 0.61 l 2.21 5.35 a 1 1 0 0 1 -0.25 1.12 l -1.41 1.28 a 6.45 6.45 0 0 0 0 9.58 l 1.41 1.28 a 1 1 0 0 1 0.25 1.12 l -2.21 5.35 a 1 1 0 0 1 -1 0.61 l -1.91 -0.09 a 6.45 6.45 0 0 0 -6.77 6.77 l 0.09 1.9 a 1 1 0 0 1 -0.61 1 l -5.35 2.21 A 0.84 0.84 0 0 1 38.81 57 Z M 20.88 53.13 l 4 1.68 l 0.8 -0.89 a 8.46 8.46 0 0 1 12.56 0 l 0.8 0.89 l 4 -1.68 l -0.06 -1.2 a 8.44 8.44 0 0 1 8.87 -8.87 l 1.2 0.06 l 1.68 -4 l -0.89 -0.8 a 8.46 8.46 0 0 1 0 -12.56 l 0.89 -0.8 l -1.68 -4 l -1.2 0.06 a 8.44 8.44 0 0 1 -8.87 -8.87 l 0.06 -1.2 l -4 -1.68 l -0.8 0.89 h 0 a 8.46 8.46 0 0 1 -12.56 0 l -0.8 -0.89 l -4 1.68 l 0.06 1.2 a 8.44 8.44 0 0 1 -8.87 8.87 l -1.2 -0.06 l -1.68 4 l 0.89 0.8 a 8.46 8.46 0 0 1 0 12.56 l -0.89 0.8 l 1.68 4 l 1.2 -0.06 a 8.44 8.44 0 0 1 8.87 8.87 Z M 37.53 9.41 h 0 Z" stroke-linecap="round"/>
              </g>
              <g transform="matrix(1 0 0 1 0 0)" id="Design_Convert">
                <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" translate(-32, -32)" d="M 32 44 A 12 12 0 1 1 44 32 A 12 12 0 0 1 32 44 Z m 0 -22 A 10 10 0 1 0 42 32 A 10 10 0 0 0 32 22 Z" stroke-linecap="round"/>
              </g>
            </g>
          </g>
        </svg>
      </button>

    </div>  

    
    <div class="flex-container" v-if="isViewTreeOption">

      <div class="treeselect-options">
        <div class="treeselect-options__value-contaier" >
          <span><input v-model="treeOptions.fullpath" type="checkbox" name="fullpath" @change="toggleFullpath"/></span>
          <label class="checkbox-label" for="fullpath">Full path</label>
        </div>

        <div class="treeselect-options__value-contaier" >
          <span><input v-model="treeOptions.disablebranchnodes" type="checkbox" name="disablebranchnodes"/></span>
          <label class="checkbox-label" for="disablebranchnodes">Disable branch nodes</label>
        </div>

        <div class="treeselect-options__value-contaier" >
          <span>&nbsp;Search:</span>

          <span><input v-model="treeOptions.fuzzyMatching" type="checkbox" name="fuzzyMatching" @change="toggleFuzzyMatching"/></span>
          <label class="checkbox-label" for="fuzzyMatching">Fuzzy matching</label>

          <span><input v-model="treeOptions.flattenSearchResults" type="checkbox" name="flattenSearchResults"/></span>
          <label class="checkbox-label" for="flattenSearchResults">Flatten</label>

          <span><input v-if="treeOptions.fuzzyMatching == false" v-model="treeOptions.searchNested" type="checkbox" name="searchNested"/></span>
          <label v-if="treeOptions.fuzzyMatching == false" class="checkbox-label" for="searchNested">Nested</label>
        </div>

        <div class="treeselect-options__value-contaier">
          <span>&nbsp;Select:</span>

          <span><input v-model="treeOptions.multiple" type="checkbox" name="Multiple" @change="toggleSingle"/></span>
          <label class="checkbox-label" for="Multiple">Multiple</label>

          <span><input v-if="treeOptions.multiple" v-model="treeOptions.flat" type="checkbox" name="flat" @change="toggleSingle"/></span>
          <label v-if="treeOptions.multiple" class="checkbox-label" for="flat">Flat</label>

          <div class="value-radio" v-if="!treeOptions.flat && treeOptions.multiple">
            <input type="radio" value="ALL" v-model="treeOptions.valueconsistsof" name="all">
            <label class="checkbox-label" for="all">All</label>

            <input type="radio" value="BRANCH_PRIORITY" v-model="treeOptions.valueconsistsof" name="branchPriority">
            <label class="checkbox-label" for="branchPriority">Branch priority</label>

            <input type="radio" value="LEAF_PRIORITY" v-model="treeOptions.valueconsistsof" name="leafPriority">
            <label class="checkbox-label" for="leafPriority">Leaf priority</label>

            <input type="radio" value="ALL_WITH_INDETERMINATE" v-model="treeOptions.valueconsistsof" name="allWithIndeterminate">
            <label class="checkbox-label" for="allWithIndeterminate">All with indeterm.</label>
          </div>

        </div>
  
      </div>

      <button class="svg-button" @click="closeTreeOptions">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(0.239382, 0, 0, 0.239382, 11.999981, 11.999981)" id="92897">
            <path style="stroke: rgb(255, 255, 255); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-64.032, -64.183)" d="M 84.815 43.399 c -0.781 -0.782 -2.047 -0.782 -2.828 0 L 64.032 61.356 L 46.077 43.399 c -0.781 -0.782 -2.047 -0.782 -2.828 0 c -0.781 0.781 -0.781 2.047 0 2.828 l 17.955 17.957 L 43.249 82.141 c -0.781 0.78 -0.781 2.047 0 2.828 c 0.391 0.39 0.902 0.585 1.414 0.585 s 1.023 -0.195 1.414 -0.585 l 17.955 -17.956 l 17.955 17.956 c 0.391 0.39 0.902 0.585 1.414 0.585 s 1.023 -0.195 1.414 -0.585 c 0.781 -0.781 0.781 -2.048 0 -2.828 L 66.86 64.184 l 17.955 -17.957 C 85.597 45.447 85.597 44.18 84.815 43.399 z M 64.032 14.054 c -27.642 0 -50.129 22.487 -50.129 50.127 c 0.002 27.643 22.491 50.131 50.133 50.131 c 27.639 0 50.125 -22.489 50.125 -50.131 C 114.161 36.541 91.674 14.054 64.032 14.054 z M 64.036 110.313 h -0.002 c -25.435 0 -46.129 -20.695 -46.131 -46.131 c 0 -25.435 20.693 -46.127 46.129 -46.127 s 46.129 20.693 46.129 46.127 C 110.161 89.617 89.47 110.313 64.036 110.313 z" stroke-linecap="round"/>
          </g>
        </svg>
      </button>

    </div>


    <div class="flex-container" v-if="isItemAdd || isItemChange">
    
      <button class="svg-button" @click="changeItem" v-if="isItemChange">
        <svg viewBox="0 0 22.567 24" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
          <g id="550066" style="" transform="matrix(1.098439, 0, 0.029913, 1.098439, 11.283512, 13.015615)">
            <path style="stroke: rgb(255, 255, 255); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(216,216,216); fill-rule: evenodd; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-10, -10)" d="M 5.9 8.1 L 4.5 9.5 L 9 14 L 19 4 L 17.6 2.6 L 9 11.2 L 5.9 8.1 L 5.9 8.1 Z M 18 10 C 18 14.4 14.4 18 10 18 C 5.6 18 2 14.4 2 10 C 2 5.6 5.6 2 10 2 C 10.8 2 11.5 2.1 12.2 2.3 L 13.8 0.7 C 12.6 0.3 11.3 0 10 0 C 4.5 0 0 4.5 0 10 C 0 15.5 4.5 20 10 20 C 15.5 20 20 15.5 20 10 L 18 10 L 18 10 Z" stroke-linecap="round"/>
          </g>
        </svg>
      </button>

      <button class="svg-button" @click="addItem" v-if="isItemAdd" :disabled="!isNewItem">
        <svg viewBox="60.09 93.747 24.057 24" xmlns="http://www.w3.org/2000/svg">
          <path style="stroke: rgb(255, 255, 255); stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0, 0, 0); fill-rule: nonzero; opacity: 1; stroke-width: 2px;" vector-effect="non-scaling-stroke" d="M 63.525 93.747 C 61.628 93.747 60.09 95.285 60.09 97.183 C 60.09 99.081 61.628 100.619 63.525 100.619 C 65.422 100.619 66.959 99.081 66.959 97.183 C 66.959 95.285 65.422 93.747 63.525 93.747 Z M 63.525 100.333 C 61.789 100.333 60.376 98.921 60.376 97.183 C 60.376 95.447 61.788 94.033 63.525 94.033 C 65.26 94.033 66.674 95.445 66.674 97.183 C 66.674 98.919 65.26 100.333 63.525 100.333 Z" stroke-linecap="round"/>
          <path style="stroke: rgb(255, 255, 255); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255, 255, 255); fill-rule: evenodd; opacity: 1;" vector-effect="non-scaling-stroke" d="M 73.4 108.672 L 72.333 109.74 L 75.762 113.171 L 83.385 105.546 L 82.317 104.478 L 75.762 111.036 L 73.4 108.672 Z M 82.622 110.121 C 82.622 113.476 79.878 116.222 76.525 116.222 C 73.171 116.222 70.427 113.476 70.427 110.121 C 70.427 106.766 73.171 104.021 76.525 104.021 C 77.134 104.021 77.668 104.097 78.202 104.249 L 79.421 103.029 C 78.506 102.724 77.516 102.495 76.525 102.495 C 72.333 102.495 68.903 105.927 68.903 110.121 C 68.903 114.315 72.333 117.747 76.525 117.747 C 80.717 117.747 84.147 114.315 84.147 110.121 L 82.622 110.121 Z" stroke-linecap="round"/>
          <line style="fill: rgb(216, 216, 216); stroke: rgb(255, 255, 255);" x1="63.489" y1="100.418" x2="63.491" y2="110.508"/>
          <line style="fill: rgb(216, 216, 216); stroke: rgb(255, 255, 255);" x1="66.252" y1="106.869" x2="66.231" y2="113.352" transform="matrix(0, -1, 1, 0, -43.868999, 176.352005)"/>
        </svg>
      </button>

      <input class="treeselect" v-model="uploadedItem.label" placeholder="Enter new item ..." />

      <button class="svg-button" @click="closeUploadItem">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(0.239382, 0, 0, 0.239382, 11.999981, 11.999981)" id="92897">
            <path style="stroke: rgb(255, 255, 255); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-64.032, -64.183)" d="M 84.815 43.399 c -0.781 -0.782 -2.047 -0.782 -2.828 0 L 64.032 61.356 L 46.077 43.399 c -0.781 -0.782 -2.047 -0.782 -2.828 0 c -0.781 0.781 -0.781 2.047 0 2.828 l 17.955 17.957 L 43.249 82.141 c -0.781 0.78 -0.781 2.047 0 2.828 c 0.391 0.39 0.902 0.585 1.414 0.585 s 1.023 -0.195 1.414 -0.585 l 17.955 -17.956 l 17.955 17.956 c 0.391 0.39 0.902 0.585 1.414 0.585 s 1.023 -0.195 1.414 -0.585 c 0.781 -0.781 0.781 -2.048 0 -2.828 L 66.86 64.184 l 17.955 -17.957 C 85.597 45.447 85.597 44.18 84.815 43.399 z M 64.032 14.054 c -27.642 0 -50.129 22.487 -50.129 50.127 c 0.002 27.643 22.491 50.131 50.133 50.131 c 27.639 0 50.125 -22.489 50.125 -50.131 C 114.161 36.541 91.674 14.054 64.032 14.054 z M 64.036 110.313 h -0.002 c -25.435 0 -46.129 -20.695 -46.131 -46.131 c 0 -25.435 20.693 -46.127 46.129 -46.127 s 46.129 20.693 46.129 46.127 C 110.161 89.617 89.47 110.313 64.036 110.313 z" stroke-linecap="round"/>
          </g>
        </svg>
      </button>

    </div>

  </div>
</template>

<script>
import Treeselect from '@/components/treeselect/Treeselect.vue'  
import emitter from '@/emitter.js'
import { searchTree } from '@/utils/searchTree.js'
import { sortTree } from '@/utils/searchTree.js'
import { fullpathTree } from '@/utils/searchTree.js'
import { clearfullpathTree } from '@/utils/searchTree.js'

export default {
  name: 'Class',
  components: { Treeselect },
  props: ['editMode', 'classOptions'],
  data () {

    return {
      renderComponent: true,
      options: [ {
        id: 'a',
        label: 'Initial load ...'
      } ],
      value: this.classOptions.filter.vl,
      
      filter: this.classOptions.filter,

      treeOptions: this.classOptions.filter.options,

      isItemAdd: false,
      isItemChange: false,
      isViewTreeOption: false,

      uploadedItem: {
        id: '',
        label: ''
      }
    }
  },
  watch: {
    value (newValue, oldValue) {
      let fake = oldValue
      oldValue = fake 

      this.filter.vl = newValue
      this.updateValue()
    }
  },

  async created () {
    try {

      let newData = false
    
      if (this.$store.getters.classes.get(this.classOptions.className).data.length === 0) {
        await this.$store.dispatch('getClassesItems_1', this.classOptions)
        newData = true
      }
      this.options = []
      this.options.push(this.$store.getters.classes.get(this.classOptions.className).data)

      //console.log('this.options, newData: ', this.options, newData)
      
      this.filter = this.classOptions.filter
      this.value = this.filter.vl

      this.treeOptions = this.filter.options
      
      if (newData) {
        sortTree(this.options[0], this.filter.vl)
        if (this.treeOptions.fullpath)  {
          fullpathTree(this.options[0], '')
        }

      }
      
      //!!! check if new
      //this.changeFilter ()

    } catch (error) {
      console.error('Treeedit created error:', error)
    } finally {
      // console.log('' )
    }
  },
  computed: {
    isNewItem () {
      return (this.uploadedItem.label.length > 0)
    },
    isSelected () {
      return ((this.filter.vl != []) && (this.filter.vl != ''))
    }

  },

  methods: {
    changeFilter () {
      //console.log('class changeFilter, this.editMode, this.filter: ', this.editMode, this.filter)
      emitter.emit(this.filter.event)
    },

    closeTreeOptions () {
      this.isViewTreeOption = false
    },
    toggleViewOption () {
      this.isViewTreeOption = !this.isViewTreeOption
    },
    toggleFullpath () {
      if (this.treeOptions.fullpath) {

        fullpathTree(this.options[0], '')

      } else {

        clearfullpathTree(this.options[0], '')
      }
      
      this.changeFilter ()
    },
    toggleFuzzyMatching () {
      if (this.treeOptions.fuzzyMatching) {

        this.treeOptions.searchNested = false
      }
    },
    toggleSingle () {
      if (this.treeOptions.flat || this.treeOptions.multiple) {

        this.treeOptions.valueconsistsof = 'ALL'
      }
      if (this.treeOptions.multiple) {
        this.filter.vl = []
      } else {
        this.treeOptions.flat = false
        this.filter.vl = ''
      }
      this.value = this.filter.vl

      this.forceRerender ()

      this.changeFilter ()
    },

    updateValue () {

      this.changeFilter ()
    },
    
    newItem () {

      this.uploadedItem.id = String(this.filter.vl)
      this.uploadedItem.label = ''

      this.isItemAdd = true
      this.isItemChange = false
    },
    editItem () {
      
      const selectedItem = searchTree(this.options[0], this.filter.vl)

      this.uploadedItem.id = String(this.filter.vl)
      this.uploadedItem.label = selectedItem.label

      this.isItemChange = true
      this.isItemAdd = false

    },
    async changeItem () {
      try {
        //upload to db
        let payload = {}
        payload.uploadedItem = this.uploadedItem
        payload.classOptions = this.classOptions

        await this.$store.dispatch('changeClassesItem_1', payload)

        //refresh/get classes
        await this.$store.dispatch('getClassesItems_1', this.classOptions)
        this.options = []
        this.options.push(this.$store.getters.classes.get(this.classOptions.className).data)
      
        //this.filter = this.classOptions.filter
        //this.value = this.filter.vl

        //this.treeOptions = this.filter.options
        
        sortTree(this.options[0], this.filter.vl)
        if (this.treeOptions.fullpath)  {
          fullpathTree(this.options[0], '')
        }

        //notify the parent
        this.changeFilter ()

        this.closeUploadItem()

        this.forceRerender()
        emitter.emit('eventComponentRerender')

      }  catch (error) {
        console.error('changeItem error:', error)
      }
    },
    async addItem () {
      try {
        //upload to db
        let payload = {}
        payload.uploadedItem = this.uploadedItem
        payload.classOptions = this.classOptions

        await this.$store.dispatch('addClassesItem_1', payload)

        //refresh/get classes
        await this.$store.dispatch('getClassesItems_1', this.classOptions)
        this.options = []
        this.options.push(this.$store.getters.classes.get(this.classOptions.className).data)

        this.filter.vl = this.$store.getters.classes.get(this.classOptions.className).result
        this.value = this.filter.vl

        sortTree(this.options[0], this.filter.vl)
        if (this.treeOptions.fullpath)  {
          fullpathTree(this.options[0], '')
        }

        //notify the parent
        this.changeFilter ()

        this.closeUploadItem()
        
        this.uploadedItem.label = ''

        this.forceRerender()

      }  catch (error) {
        console.error('addItem error:', error)
      }
    },
    closeUploadItem () {
      this.isItemAdd = false
      this.isItemChange = false
    },
    forceRerender() {
      //Remove the component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        //Add the component back in
        this.renderComponent = true
      })
    },

  }
}
</script>

<style lang="scss">
  @import '@/assets/tree-edit.scss';
</style>
